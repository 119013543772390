/*

red color of logo: #e41e25

*/

.hero .content {
    min-width: 100vw;
    min-height: 100vh;
}

.navbar {
    min-width: 100vw;
    max-height: 20vh;
    background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(244,163,166,1) 40%, rgba(228,30,37,1) 70%);
}



.navbar .navbarButton{
    /*color: #e41e25;*/
    color: white;
    display: block;
}


.navbar .navbarButton-currentPage{
    /*color: #e41e25;*/
    color: black;
    background-color: whitesmoke;
    display: block;
}

.navbar .navbarButton:hover{
    /*color: #e41e25;*/

    font-size: 14px;
    color: black;
    background-color: whitesmoke;
}

.logo {
    max-width: 200px;
    height: auto;
    margin-left: 100px;
}

/*Home page*/

.hero {
    width: 100%;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    background-blend-mode: multiply;
    background-image: url('https://lh3.googleusercontent.com/p/AF1QipNYEP2uEedEXI3fsdGANRnsjbPAP04erig7mTjd=s680-w680-h510');
    background-color: #676767;
    background-size: cover;
}


/* Style the content container */
.hero .content {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    font-family: 'Hero New', sans-serif;
    /* Add more styling for your content here */
}

.hero .content p{
    font-size: 18px;
}
.hero .content h1{
    padding-left: 10%;
    font-size: 60px;
    font-weight: 300;


}
.hero .content h2{
    padding-left: 10%;
    font-size: 35px;
    font-weight: 300;

}
.hero .content Button{
    color: whitesmoke;
    font-size: 25px;
    border-color: whitesmoke;
    align-self: end;
    font-family: 'Hero New', sans-serif;
    font-weight: 300;
    margin-top: 10%;
    align-self: center;
}

/*.hero {*/
/*    width: 100vw;*/
/*    height: auto;*/
/*}*/

/*.hero .img {*/
/*    object-fit: cover;*/
/*    overflow: hidden;*/
/*    width: auto;*/
/*    height: auto;*/
/*    filter: blur(20px);*/
/*}*/

/* about page */

.about-section {
    font-family: 'Roboto', sans-serif;
    color: gray;
}

.about-section .container{
    margin-left: 10vw;
    justify-content: center;
    align-items: center;
    height: auto;
}


.about-section Card{
    margin-left: 10vw;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 50vh;
    margin-top: 3vh;
}


.container h2::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 1%;
}

.container  h1{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}


.container  h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}


.about-section .container-about{
    margin-left: 10vw;
    justify-content: center;
    align-items: center;
    height: auto;
}


.about-section p {
    font-size: 16px;
}

.container {
    display: flex;
    flex-direction: row;
    width: 80vw;
    height: 50vh;
    margin-top: 3vh;
    box-shadow: 20px 5px 5px gray;
    overflow: hidden;
}
.container h2{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}


.container h2::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 1%;
}

.container  h1{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}


.container  h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}


.container-about{
    display: grid;
    width: 80vw;
    margin-top: 3vh;
    box-shadow: 20px 5px 5px gray;
}
.container-about h2{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 30px;
}


.container-about h2::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 1%;
    margin-left: 30px;

}

.container-about  h1{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 30px;

}


.container-about  h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
    margin-left: 30px;

}
.container-about p{
    margin-left: 30px;
}
.about-section-image img {
    width: 100%;
}

.right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 20px;
}

.right h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.right p {
    font-size: 16px;
    text-align: left;
}

.DPF {
    padding-left: 15vw;
    padding-right: 15vw;
    overflow: hidden;
    padding-top: 10vh;
}

.DPF img{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: auto;
    border-radius: 50%;
}

.irregular-image {
    position: relative;
    border-radius: 50%; /* Creates a circular shape */
}

.irregular-image img {
    width: 100%;
    height: auto;
    display: block;
    mask-image: url('images/mask-image.png');
    mask-size: cover;
}

.DPF p {
    color: gray;
    padding-top: 14px;
    font-family: 'Roboto', sans-serif;
}

.DPF li {
    color: gray;
    padding-top: 14px;
    font-family: 'Roboto', sans-serif;
}

.DPF h2{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}

.DPF h2::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 1%;
}

.DPF h1{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}

.container-dpf {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 10vh;
}

.DPF h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}

.DPF iframe{
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 300px;
    margin-bottom: 10px;
}

.separator {
    width: 100vw;
    height: 150px;
    background-color: #e41e25;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.footer {
    width: 100vw;
    height: auto;
    background-color: #676767;
    margin-top: 5vh;
    color: white;
}

.footer p {
    color: whitesmoke;
    padding-left: 5vw;
    display: flex;
}

.footer a {
    color: whitesmoke;
    display: flex;
}


.footer h1{
    font-family: 'Roboto', sans-serif;
    color: whitesmoke;
    position: relative;
    display: inline-block;
}

.footer h2{
    padding-left: 5vw;
    font-family: 'Roboto', sans-serif;
    color: whitesmoke;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}

.footer h2::after {
    margin-left: 5vw;
    content: "";
    position: absolute;
    width: 30%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 1%;
}

.footer h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}


.contact-form-container {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.contact-form-container h1{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
}

.contact-form-container h2{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}

.contact-form-container h2::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 1%;
}

.contact-form-container h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}

.contact-form-container Button{
    color: #e41e25;
    border-color: #e41e25;
}

.contact-page {
    width: 100vw;
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.contact-page iframe{
    width: auto;
    height: auto;
    min-height: 60vh;
    min-width: 30vw;
    border: none;
}

.contact-form-container p {
    font-size: 18px;
    color: #e41e25;
}


.contact-page  h1{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}


.contact-page  h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}


.contact-page  h2{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}


.contact-page  h2::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}

.contact-page p {
    font-family: 'Roboto', sans-serif;
    color: gray;
}

.centered-items {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.styled-text  h1{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}


.styled-text h1::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 30%;
}


.styled-text h2{
    font-family: 'Roboto', sans-serif;
    color: #282c34;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}


.styled-text h2::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e41e25; /* Adjust the color to your preference */
    bottom: -4px;
    left: 10%;
}

.styled-text p {
    font-family: 'Roboto', sans-serif;
    color: gray;
}

.styled-text li {
    color: gray;
    padding-top: 14px;
    font-family: 'Roboto', sans-serif;
    list-style-type: none
}



@media only screen and (max-width: 780px) {

    iframe {
        padding-left: 12%;
    }

    .about-section-image {
        visibility: hidden;
    }

    .hero .content h1{
        font-size: 35px;
    }

    .hero .content h2{
        font-size: 20px;
    }


    .hero .content Button{
        font-size: 20px;
        width: auto;
    }

    .location-info {
        padding-left: 15%;
    }

    .DPF {
        padding-right: 0vw;
        overflow: hidden;
        padding-top: 0vh;
    }
    .DPF img{
        display: none;
    }


    .DPF iframe{
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 0;
    }

    .DPF h2 {
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .DPF p {
        margin-bottom: 0px;
    }

    .about-section iframe{
        width: auto;
        height: auto;
        min-height: 60vh;
        min-width: 30vw;
        border: none;
        padding-left: 0;
    }

    .about-section .location-info{
        padding-left: 0px;
    }



}